<template>
	<div class="main-content">
		<!-- <i class="el-icon-setting" style="color:#409eff"></i> -->
		<div class="content-box" v-loading='loading'>
			<!-- 浏览 -->
			<div class="browse-box">
				<div class="top" :class="titleEditShow?'browse-border':''">
					<img class="top-bg" src='https://cdn.dkycn.cn/images/melyshop/pc-mallHomeBg.png' />
					<div class="top-title">{{dataList.PageName}}</div>
				</div>
				<div class="search" :class="searchEditShow?'browse-border':''">
					<div class="search-input">
						<el-input disabled placeholder="请输入商品名称" v-model="dataList.HotWords">
							<i slot="prefix" class="el-input__icon el-icon-search"></i>
						</el-input>
					</div>
					<img class="scan" v-show='dataList.IsDisplayScan' src="http://cdn.dkycn.cn/images/melyshop/scan.png" />

				</div>
				<div class="tab" :class="tabEditShow?'browse-border':''">
					<el-tabs class="tab-content" v-model="tabsName" style='width:100%;'>
						<el-tab-pane :key="item.Id" v-for="(item) in dataList.DetailList" :label="item.Title">
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>

			<!-- 编辑 -->
			<div class="edit-box" style="margin-left:25px;">

				<div class="top item">
					<div class="icon-box" style="width:30px;height:30px;" @click="handleTitleShow">
						<i class="el-icon-setting" style="color:#409eff"></i>
					</div>
					<div class="dialog-box" v-if='titleEditShow'>
						<i class="el-icon-close" @click="handleTitleShow"></i>
						<div class="dialog-top">
							<span class="dialog-title">页面标题</span>
						</div>
						<div class="dialog-edit">
							<span>页面标题</span>
							<el-input v-model="dataList.PageName" maxlength="50" style="width:280px;height:30px;margin-left:25px;"></el-input>
						</div>
					</div>
				</div>

				<div class="search item">
					<div class="icon-box" style="width:30px;height:30px;" @click="handleSearchShow">
						<i class="el-icon-setting" style="color:#409eff"></i>
					</div>
					<div class="dialog-box" v-if='searchEditShow'>
						<i class="el-icon-close" @click="handleSearchShow"></i>
						<div class="dialog-top">
							<span class="dialog-title">商品搜索</span>
						</div>
						<div class="dialog-edit">
							<span style="margin-right:25px;">搜索热词</span>
							<el-input v-model="dataList.HotWords" maxlength="20" placeholder="请输入商品名称" style="width:280px;height:30px;"></el-input>

						</div>
						<div class="dialog-edit-search show">
							<span style="margin-right:25px;">显示和隐藏</span>
							<el-radio-group v-model="dataList.IsDisplaySearch">
								<el-radio :label="true">显示</el-radio>
								<el-radio :label="false">隐藏</el-radio>
							</el-radio-group>
						</div>
						
						<div class="dialog-edit-search show">
							<span style="margin-right:25px;">扫码购入口</span>
							<el-radio-group v-model="dataList.IsDisplayScan">
								<el-radio :label="true">显示</el-radio>
								<el-radio :label="false">隐藏</el-radio>
							</el-radio-group>
						</div>
					</div>
				</div>

				<div class="tab item">
					<div class="icon-box" style="width:30px;height:30px;" @click="handleTabShow">
						<i class="el-icon-setting" style="color:#409eff"></i>
					</div>
					<div class="dialog-box" v-if='tabEditShow' style="margin-bottom:100px;">
						<i class="el-icon-close" @click="handleTabShow"></i>
						<div class="dialog-top">
							<span class="dialog-title">顶部导航</span>
						</div>

						<div class="tab-mark">长按导航可拖动排序</div>
						<div class="list-wrap" style="width:100%;margin-bottom: 40px;">
							<draggable 
								style='width:100%;' 
								v-model="dataList.DetailList" 
								:options="{
									animation: 150,
									ghostClass: 'sortable-ghost',
									chosenClass: 'chosenClass',
									scroll: true,
									scrollSensitivity: 200
								}">
								<div class="tab-item" v-for="(item,index) in dataList.DetailList" :key="index">
									<div class="tab-edit">
										<div class="title">
											<span style="margin-right:10px;">标题：</span>
											<el-input v-model="item.Title" maxlength="6" style="width:310px;height:30px;"></el-input>
											<!-- <el-input v-model="item.Title" style="width:310px;height:30px;"></el-input> -->
											<!-- 导航{{chnUnitChar[parseInt((index+1)/10)] + chnNumChar[(index+1)%10]}} -->
										</div>

										<div class="select-link">
											<span style="margin-right:10px;">链接：</span>
											<div v-if="item.RelationName&&item.RelationName!=''" @click="handleShow(item,index)" class="select-link-name">
												<span class="select-page-name">{{item.RelationName}}</span>
												<i class="el-icon-arrow-down"></i>
											</div>
											<div v-else @click="handleShow(item,index)" class="link-name">
												<div class="page-name">请选择页面</div>
												<i class="el-icon-arrow-down"></i>
											</div>
										</div>
									</div>
									<el-button @click="handleDeleteTab(index)" type="text" style="color:#f46b6b;margin-left:10px;">删除</el-button>
								</div>

							</draggable>
						</div>
						<div class="tab-add" v-if="dataList.DetailList.length<10">
							<div style="cursor:pointer" @click="handleAddTab">
								<i class="el-icon-plus"></i>
								<span>添加顶部导航</span>
							</div>
						</div>

						<div class="tab-show">
							<span style="margin-right:25px;">显示和隐藏</span>
							<el-radio-group v-model="dataList.IsDisplayDetail">
								<el-radio :label="true">显示</el-radio>
								<el-radio :label="false">隐藏</el-radio>
							</el-radio-group>
						</div>
					</div>
				</div>

			</div>
		</div>

		<!-- 底部按钮 -->
		<div class="footer">
			<el-button style="width:200px" @click="saveEdit(false)" :loading="loading">保存</el-button>
			<el-button type="primary" style="width:200px;margin-left:105px;" @click="saveEdit(true)" :loading="loading">发布</el-button>
		</div>

		<!-- 选择的页面弹框 -->
		<el-dialog title="选择页面" :visible.sync="dialogVisible" width="700px">
			<div style="border-bottom:1px solid #ddd;margin-top:-20px;"></div>
			<el-radio-group v-model="tabPosition" style="margin: 20px 0;">
				<el-radio-button :label="0">商品分组</el-radio-button>
				<el-radio-button :label="1">自定义页面</el-radio-button>
			</el-radio-group>

			<div v-if="tabPosition==0" class="dialog-table">
				<el-table :data="proList" style="width: 100%" v-loading='proLoading'>
					<el-table-column width="35">
						<template scope="scope">
							<el-radio :label="scope.row.Id" v-model="ProRadio" @change.native="getProRow(scope.row)">&nbsp;</el-radio>
						</template>
					</el-table-column>
					<el-table-column :key="1" label="分组名称" prop="GroupName" width="470px"></el-table-column>
					<el-table-column :key="2" label="关联商品数量" prop="ProductCount"></el-table-column>
				</el-table>
				<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30]" :page-size="page.size"
				 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>

			<div v-else class="dialog-table">
				<el-table :data="customizeList" style="width: 100%" v-loading='cusLoading'>
					<el-table-column width="35">
						<template scope="scope">
							<el-radio :label="scope.row.Id" v-model="CustomizeRadio" @change.native="getCustomizeRow(scope.row)">&nbsp;</el-radio>
						</template>
					</el-table-column>
					<el-table-column :key="3" label="页面名称" prop="PageName" width="470px"></el-table-column>
					<el-table-column :key="4" label="创建时间" prop="AddTime"></el-table-column>
				</el-table>
				<el-pagination v-if="cuspage.total" style="margin-top:20px;float:right;" @size-change="handleCusSizeChange"
				 @current-change="handleCusCurrentChange" :current-page="cuspage.current" :page-sizes="[10, 20, 30]" :page-size="cuspage.size"
				 layout="total, sizes, prev, pager, next, jumper" :total="cuspage.total">
				</el-pagination>
			</div>

			<div class="dialog-footer">
				<el-button type="primary" style="width:200px;" @click="saveSelection">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import apiList from '@/api/other'
	import draggable from "vuedraggable";
	export default {
		components: {
			draggable
		},
		data() {
			return {
				titleEditShow: true,
				searchEditShow: false,
				tabEditShow: false,
				loading: false,
				proLoading: false,
				cusLoading: false,
				tabsName: 0,
				dialogVisible: false,
				tabPosition: 0,
				proList: [],
				customizeList: [],
				checked: false,
				selection: {},
				ProRadio: 0,
				CustomizeRadio: 0,
				cuspage: {
					size: 10,
					total: 10,
					current: 1
				},
				page: {
					size: 10,
					total: 10,
					current: 1
				},
				dataList: {
					DetailList: [],
					HotWords: "",
					IsDisplayDetail: true,
					IsDisplaySearch: true,
					IsDisplayScan: true,
					PageName: ""
				},
				chnNumChar: ["", "一", "二", "三", "四", "五", "六", "七", "八", "九"],
				chnUnitChar: ["", "十", "二十", "三十", '四十', '五十', '六十', '七十', '八十', '九十', '一百'],
				sortIndex: 0,
				currentSelectIndex: 0
			}
		},
		created() {
			this.getList()
		},
		methods: {
			async getProduce() {
				this.proLoading = true
				try {
					let data = {
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
					}
					let res = await apiList.mallShopDecorateSelectGroup(data)
					this.proList = res.Result.Results
					this.page.total = res.Result.Total
				} catch (e) {

				} finally {
					this.proLoading = false
				}
			},
			async getCusProduce() {
				this.proLoading = true
				try {
					let data = {
						Skip: (this.cuspage.current - 1) * this.cuspage.size,
						Take: this.cuspage.size,
					}
					let res = await apiList.mallShopDecorateSelect(data)
					this.customizeList = res.Result.Results
					this.cuspage.total = res.Result.Total
				} catch (e) {

				} finally {
					this.cusLoading = false
				}
			},
			async getList() {
				this.loading = true
				try {
					let res = await apiList.homeNavigationIndex()
					this.dataList = res.Result
					this.sortIndex = this.dataList.DetailList.length || 0
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			//单选按钮
			getProRow(row) {
				this.checked = true
				this.selection = row
			},
			getCustomizeRow(row) {
				this.checked = true
				this.selection = row
			},
			//选择链接
			handleShow(val, index) {
				//初始化
				this.selection = {}
				this.CustomizeRadio = 0
				this.ProRadio = 0
				this.tabPosition = 0

				this.currentSelectIndex = index

				this.page.size = 10
				this.page.current = 1
				this.cuspage.size = 10
				this.cuspage.current = 1

				this.getCusProduce()
				this.getProduce()

				if (val.Type == 0) {
					this.ProRadio = val.RelationId
					this.tabPosition = val.Type
					this.selection = {
						GroupName: val.RelationName,
						Id: val.RelationId,
					}
				} else if (val.Type == 1) {
					this.CustomizeRadio = val.RelationId
					this.tabPosition = val.Type
					this.selection = {
						GroupName: val.RelationName,
						Id: val.RelationId,
					}
				}
				this.dialogVisible = true
				this.$forceUpdate()
			},
			//关闭弹框，曝光选择
			saveSelection() {
				if (!Object.getOwnPropertyNames(this.selection)) {
					this.$message({showClose: true,
						type: 'error',
						message: '请选择商品'
					})
				} else {
					if (this.tabPosition == 0) {
						this.dataList.DetailList[this.currentSelectIndex].RelationName = this.selection.GroupName
						this.dataList.DetailList[this.currentSelectIndex].RelationId = this.selection.Id
						this.dataList.DetailList[this.currentSelectIndex].Type = this.tabPosition
					} else {
						this.dataList.DetailList[this.currentSelectIndex].RelationName = this.selection.PageName
						this.dataList.DetailList[this.currentSelectIndex].RelationId = this.selection.Id
						this.dataList.DetailList[this.currentSelectIndex].Type = this.tabPosition
					}
					this.dialogVisible = false
					this.checked = false
				}
			},
			handleAddTab() {
				this.sortIndex += 1
				let Title = ''
				let ten = parseInt(this.sortIndex / 10)
				let digits = this.sortIndex % 10
				Title = '导航' + this.chnUnitChar[ten] + this.chnNumChar[digits]
				this.dataList.DetailList.push({
					HomeNavigationId: 0,
					RelationId: 0,
					RelationName: '',
					Title: Title,
					Type: 2
				})
			},
			handleDeleteTab(index) {
				this.dataList.DetailList.splice(index, 1)
				this.$forceUpdate()
			},
			//保存
			async saveEdit(IsRelease) {
				this.loading = true
				try {
					this.dataList.IsRelease = IsRelease
					let res = await apiList.homeNavigationSave(this.dataList)
					if (res.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: IsRelease?'发布成功':'保存成功'
						})
					}
				} catch (e) {
					this.$message({showClose: true,
						type: 'error',
						message: IsRelease?'发布失败':'保存失败'
					})
					this.loading = false
				} finally {
					this.loading = false
					this.getList()
				}
			},
			handleTitleShow() {
				this.titleEditShow = !this.titleEditShow; 
				if (this.titleEditShow){
					this.searchEditShow = false;
					this.tabEditShow = false;
				}
			},
			handleSearchShow() {
				this.searchEditShow = !this.searchEditShow;
				if (this.searchEditShow){
					this.titleEditShow = false;
					this.tabEditShow = false;
				}
			},
			handleTabShow() {
				this.tabEditShow = !this.tabEditShow;
				if (this.tabEditShow){
					this.searchEditShow = false;
					this.titleEditShow = false;
				}
			},
			handleSizeChange(val) {
				this.page.size = val
				this.getProduce()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.getProduce()
			},
			handleCusSizeChange(val) {
				this.cuspage.size = val
				this.getCusProduce()
			},
			handleCusCurrentChange(val) {
				this.cuspage.current = val
				this.getCusProduce()
			},
		}
	}
</script>

<style lang="less" scoped>
	.main-content {
		overflow: auto;

		.content-box {
			display: flex;
			flex-direction: row;
			margin-bottom: 110px;
		}

		.browse-box {
			flex: 0 0 auto;
			margin-left: 180px;
			width: 375px;
			height: 100%;
			height: 700px;
			background-color: #f5f5f5;
			box-shadow: 0 0 10px #dbdbdb;

			.top {
				width: 100%;
				height: 85px;
				position: relative;

				img {
					width: 100%;
					height: 100%;
				}

				.top-title {
					position: absolute;
					bottom: 0;
					width: 50%;
					height: 40px;
					left: 25%;
					line-height: 40px;
					text-align: center;
					font-size: 16px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.browse-border {
				border: 2px solid #409eff;
			}

			.search {
				width: 100%;
				height: 45px;
				line-height: 43px;
				overflow: hidden;
				padding:0 10px;
				// margin-top: 150px;
				background-color: #fff;
				display:flex;

				.search-input {
					-webkit-box-flex: 1;
					-ms-flex: 1 1 auto;
					flex: 1 1 auto;
					// width: calc(100% - 30px);
					margin-left: 5px;
				}
				.scan{
					-webkit-box-flex: 0;
					-ms-flex: 0 0 auto;
					flex: 0 0 auto;
					width: 36px;
					object-fit:contain;
					margin-left: 5px;
				}

				::v-deep .el-input--medium .el-input__inner {
					border-radius: 50px;
					height: 30px;
					line-height: 30px;
				}
			}

			.tab {
				::v-deep .el-tabs__item {
					height: auto !important;
				}

				padding: 0 5px;
				// margin-top: 225px;
				width: 100%;
				height: 45px;
				background-color: #fff;

				// margin-bottom: 150px;
				::v-deep .el-tabs__nav-wrap::after {
					background-color: #fff;
				}

				::v-deep .el-tabs__item.is-active {
					color: #ff547b;
				}

				::v-deep .el-tabs__active-bar {
					background-color: #ff547b;
				}

				::v-deep .el-tabs__item:hover {
					color: #ff547b;
				}

				::v-deep .el-tabs__item.is-active {
					color: #ff547b;
				}

				.tab-content {
					margin: 0 auto;
				}
			}
		}

		.edit-box {
			position: relative;

			.item {
				display: flex;
				flex-direction: row;
			}

			.icon-box {
				width: 30px;
				height: 30px;
				background-color: #fff;
				text-align: center;

				.el-icon-setting {
					margin-top: 7px;
				}
			}

			.dialog-box {
				width: 480px;
				padding: 0 20px;
				margin-left: 5px;
				background-color: #fff;
				position: relative;

				.el-icon-close {
					position: absolute;
					top: 15px;
					right: 15px;
					font-size: 18px;
					color: #a1a1a1;
				}

				.dialog-top {
					height: 60px;
					line-height: 60px;
					border-bottom: 1px solid #ddd;

					.dialog-title {
						font-size: 18px;
					}
				}

				.dialog-edit {
					line-height: 80px;
					display: flex;
					flex-direction: row;
					margin-left: 35px;
				}

				.dialog-edit-search {
					margin-bottom: 30px;
					display: flex;
					flex-direction: row;
					margin-left: 35px;
				}

				.show {
					align-items: center;
				}
				.tab-mark{
					line-height: 40px;
					font-size: 14px;
					color: #909399;
				}
				.tab-item {
					margin-bottom: 25px;
					display: flex;
					flex-direction: row;
					overflow: hidden;

					.tab-edit {
						width: 400px;
						background: #f5f5f5;
						padding: 10px;
						line-height: 40px;

						.link {
							display: flex;
							flex-direction: row;
							line-height: 30px;

						}

						.select-link {
							margin-top: 10px;
							display: flex;
							flex-direction: row;
							line-height: 30px;

							.select-link-name {
								font-size: 14px;
								width: 310px;
								padding: 0 10px;
								background-color: #409eff;
								display: flex;
								flex-direction: row;
								align-items: center;

								.select-page-name {
									width: calc(100% - 25px);
									color: #fff;
									line-height: 30px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
								}

								.el-icon-arrow-down {
									color: #fff;
									margin-left: 10px;
									font-size: 18px;
								}
							}

							.link-name {
								.page-name {
									color: #409eff;
									font-size: 14px;
								}

								.el-icon-arrow-down {
									color: #409eff;
									margin-left: 10px;
									font-size: 18px;
								}

								display: flex;
								flex-direction: row;
								align-items: center;
							}
						}
					}
				}

				.tab-add {
					background: #f5f5f5;
					margin-top: 25px;
					line-height: 30px;
					padding: 0 10px;
					color: #409eff;
					width: 400px;

					.el-icon-plus {
						margin-right: 10px;
					}
				}

				.tab-show {
					line-height: 60px;
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}

			.item {
				display: flex;
				flex-direction: row;
			}

			.search {
				top: 92px;
				position: absolute;
			}

			.tab {
				top: 138px;
				position: absolute;
			}
		}
	}

	.footer {
		text-align: center;
		line-height: 80px;
		position: fixed;
		bottom: 0px;
		width: 100%;
		background-color: #b3b3b3;
	}

	.dialog-table {
		overflow: hidden;
		margin-bottom: 50px;
	}

	.dialog-footer {
		text-align: center;
	}
</style>
